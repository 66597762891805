import React from 'react';
import { ClipLoader } from 'react-spinners';

type Props = {
  size: 'lg' | 'md' | 'sm';
};

function LoadingIndicator({ size }: Props) {
  let loadingSize;
  if (size === 'lg') {
    loadingSize = '30px';
  } else if (size === 'md') {
    loadingSize = '20px';
  } else if (size === 'sm') {
    loadingSize = '10px';
  }
  return <ClipLoader color="#ca1611" size={loadingSize} />;
}

export default LoadingIndicator;
