// Hero
import heroImg1 from './1-min.png';
import heroImg2 from './2-min.png';
import heroImg3 from './3-min.png';
import heroImg4 from './4-min.png';
import heroImg5 from './5-min.png';
import heroImg6 from './6-min.png';

// Discover Our Menu
import breadImg from './Bread-min.jpg';
import cakesImg from './Cakes-min.jpg';
import foodImg from './Food-min.jpg';
import pastriesImg from './Pastries-min.jpg';
import proteinImg from './Protein-min.jpg';
import sharwamaImg from './Sharwama-min.jpg';

// Mobile App
import mobileAppImg from './MOBILE-min.png';
import googlePlayIcon from './Google_Play_2016_icon.png';
import appleStoreIcon from './Apple_Store_logo.png';

// About Us
import aboutUsImg from './About_US-min.jpeg';
import aboutUsImg2 from './about-us-min.jpg'

// Site Pages
import offersImg from './OFFERS-min.png';
import fanClubImg from './FANCLUB-min.png';
import careerImg from './CAREERS-min.png';
import blogImg from './blog-min.png';

// Newsletter
import newsletterImg from './Newsletter-min.jpg';

// Logo
import logoRed from './logo-red.png';
import logoWhite from './logo-white.png';

// Management
import mdImg from './mgt/md.png';
import edOpsImg from './mgt/ed-ops.png';
import fcImg from './mgt/fc.png';
import ghgsImg from './mgt/ghgs.png';
import hhraImg from './mgt/hhra.png';
import haicImg from './mgt/haic.png';
import hsmImg from './mgt/hsm.png';
import itmImg from './mgt/itm.png';

// ====================================================
// Exports
export {
  heroImg1,
  heroImg2,
  heroImg3,
  heroImg4,
  heroImg5,
  heroImg6,
  breadImg,
  cakesImg,
  foodImg,
  pastriesImg,
  proteinImg,
  sharwamaImg,
  mobileAppImg,
  googlePlayIcon,
  appleStoreIcon,
  aboutUsImg,
  aboutUsImg2,
  offersImg,
  fanClubImg,
  careerImg,
  blogImg,
  newsletterImg,
  logoRed,
  logoWhite,
  mdImg,
  edOpsImg,
  fcImg,
  ghgsImg,
  hhraImg,
  haicImg,
  hsmImg,
  itmImg,
};
