'use client';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Image from 'next/image';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Bars3Icon,
  MapIcon,
  MapPinIcon,
  ShoppingCartIcon,
} from '@heroicons/react/24/outline';

import { HeaderNavMenu } from './NavMenu';
import {
  updateUserLocation,
  hydrateUserLocation,
} from '@/redux/slices/userLocation.slice';
import ModalCart from './ModalCart';
import Modal from './Modal';
import LocationInput from './LocationInput';
import NavMenuDialog from './NavMenuDialog';
import { logoRed } from '@/assets/images';
import { useInView } from 'react-intersection-observer';
import { isDeliveryTime } from '@/utils/helpers';
import AppStorage from '@/utils/helpers';

export default function Header({ sticky }: { sticky?: boolean }) {
  // State to control visibility of modals
  const [viewModal, setViewModal] = useState({
    menu: false,
    location: false,
    cart: false,
    disableOrder: false,
  });

  const { ref, inView } = useInView({
    threshold: 0,
  });

  // State to store location data
  const [location, setLocation] = useState<any | null>(null);

  // Redux hooks to access and dispatch actions
  const locationState = useSelector(
    (state: any) => state.userLocation.location
  );
  const dispatch = useDispatch();

  // Hydrate location from AppStorage on initial render
  useEffect(() => {
    const storedLocation = JSON.parse(AppStorage.get('location') as string);
    if (storedLocation) {
      dispatch(hydrateUserLocation(storedLocation));
    }
  }, [dispatch]);

  // Update location state when locationState in Redux changes
  useEffect(() => {
    if (locationState) {
      setLocation(locationState);
      setViewModal((prev) => ({ ...prev, location: false }));
    } else {
      setViewModal((prev) => ({ ...prev, location: true }));
    }
  }, [locationState]);

  // Cart state from Redux
  const { cart } = useSelector((state: any) => state.cart); // Accessing items array within cart

  // Calculate total number of items in cart
  const itemCount = cart?.reduce(
    (total: number, item: any) => total + item.quantity,
    0
  );

  return (
    <header
      ref={ref}
      className={`p-5 bg-crunchies-white ${sticky && 'sticky top-0 z-50'}`}
    >
      <div className="w-full max-w-[1440px] mx-auto grid grid-cols-3 items-center gap-5 md:flex md:justify-between">
        {/* Logo */}
        <div className="gap-10 grid md:flex w-max">
          <a href="/">
            <Image
              src={logoRed}
              alt="Crunchies Fried Chicken logo"
              className="w-20"
              priority
            />
          </a>

          {/* Location button */}
          <button
            className="py-3 w-max flex gap-2 items-center"
            onClick={() =>
              setViewModal((prev) => ({ ...prev, location: true }))
            }
          >
            <MapPinIcon className="w-6 text-crunchies-red" />
            <div className="text-xs w-32 md:w-full text-left">
              {location?.name || 'Set location'}
            </div>
          </button>

          {/* Location Modal */}
          <Modal
            closeModal={() =>
              setViewModal((prev) => ({ ...prev, location: false }))
            }
            openModal={viewModal.location}
            modalTitle="Hey Cruncher!"
            shouldCloseOnOverlayClick={true}
          >
            <LocationInput />
            <button
              className="text-white flex gap-2 items-center bg-crunchies-red shadow px-5 py-3 w-max mx-auto rounded-full"
              onClick={() => {
                const newLocation = JSON.parse(
                  AppStorage.get('location') as string
                );
                setViewModal((prev) => ({ ...prev, location: false }));
                setLocation(newLocation);
                dispatch(updateUserLocation(newLocation));
              }}
            >
              <MapIcon className="w-6" />
              <div>Set location</div>
            </button>
          </Modal>
        </div>

        {/* Desktop Navigation */}
        <div className="hidden lg:block">
          <HeaderNavMenu />
        </div>

        <div className="col-span-2 grid gap-10 md:flex justify-end">
          {/* Order button */}
          <a
            href="/menu"
            className="text-white flex gap-2 items-center bg-crunchies-red shadow px-5 py-3 w-max ml-auto rounded-xl"
          >
            <ArrowRightIcon className="w-3 animate-ping" />
            <div className="font-bold">Order Now</div>
            <ArrowLeftIcon className="w-3 animate-ping" />
          </a>

          <div className="flex justify-between">
            {/* Shopping cart button */}
            <div
              className={`${
                !inView &&
                'fixed top-1/3 right-0 p-2 rounded-l-full shadow-lg bg-crunchies-red z-10'
              }`}
            >
              <button
                className="px-5 py-3 w-max relative"
                onClick={() =>
                  isDeliveryTime()
                    ? setViewModal((prev) => ({
                        ...prev,
                        cart: !viewModal.cart,
                      }))
                    : setViewModal((prev) => ({ ...prev, disableOrder: true }))
                }
              >
                {itemCount > 0 && (
                  <div
                    className={`absolute top-1 right-3 text-xs rounded-full w-5 h-5 flex items-center justify-center ${
                      inView
                        ? 'bg-crunchies-red text-crunchies-white'
                        : 'bg-crunchies-white text-crunchies-red'
                    }`}
                  >
                    {itemCount}
                  </div>
                )}
                <ShoppingCartIcon
                  className={`w-6 ${
                    inView ? 'text-crunchies-red' : ' text-crunchies-white'
                  }`}
                />
              </button>
              {/* Cart Modal */}
              <ModalCart
                open={viewModal.cart}
                closeModal={() =>
                  setViewModal((prev) => ({ ...prev, cart: false }))
                }
              />
            </div>

            {/* Mobile/Tablet Navigation button */}
            <button
              className="py-3 px-5 w-max lg:hidden"
              onClick={() => setViewModal((prev) => ({ ...prev, menu: true }))}
            >
              <Bars3Icon className="w-6 text-crunchies-red" />
            </button>
          </div>

          {/* Menu Dialog for mobile/tablet */}
          <NavMenuDialog
            openModal={viewModal.menu}
            closeModal={() =>
              setViewModal((prev) => ({ ...prev, menu: false }))
            }
          />
        </div>
      </div>

      {/* Disable order modal */}
      <Modal
        modalTitle="Oops..."
        openModal={viewModal.disableOrder}
        closeModal={() =>
          setViewModal((prev) => ({ ...prev, disableOrder: false }))
        }
      >
        <div>
          Dear customer, online order services are unavailable at this time. Our
          services will be active from 8am to 6pm. Thank you!
        </div>
        <button
          className="text-white flex gap-2 items-center bg-crunchies-red shadow px-5 py-3 w-max mx-auto rounded-full"
          onClick={() =>
            setViewModal((prev) => ({ ...prev, disableOrder: false }))
          }
        >
          Okay! I&apos;ll wait
        </button>
      </Modal>
    </header>
  );
}
