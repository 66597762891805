import axiosInstance from '@/utils/https';

export type SupportProps = {
  name: string;
  emailAddress: string;
  description: string;
  title: string;
  phoneNumber: string;
};

export const CreateSupport = async (payload: SupportProps) => {
  try {
    const url = `api/v1/fans/create-task`;
    const response = await axiosInstance.post(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};
