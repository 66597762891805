import React, { useState } from 'react';
import Modal from './Modal';
import { BiSupport } from 'react-icons/bi';
import { CreateSupport } from '@/services/support.api';
import { toast } from 'react-toastify';

export default function SupportModalForm() {
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    emailAddress: '',
    description: '',
    title: '',
    phoneNumber: '',
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    try {
      await CreateSupport(formData);
      toast.success('Complaint successfully created!');

      setLoading(false);
      setOpenModal(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
      console.log(error);
    }
  };

  return (
    <>
      <button
        onClick={() => setOpenModal(true)}
        className="flex gap-2 justify-between text-crunchies-red items-center bg-crunchies-white shadow-lg p-3 rounded-lg"
      >
        <div className="flex gap-2 items-center">
          <BiSupport className="w-6 lg:hidden" />
          <div>Support</div>
        </div>
      </button>

      <Modal
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
        modalTitle="Support Request"
      >
        <form onSubmit={handleSubmit} className="grid gap-4">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Title of Complaint
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              className="flex w-full py-2 px-10 border outline-none my-1 pl-5 font-poppinsLight focus:border-gray-700 focus:ring-gray-700 rounded-lg"
              required
            />
          </div>
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="flex w-full py-2 px-10 border outline-none my-1 pl-5 font-poppinsLight focus:border-gray-700 focus:ring-gray-700 rounded-lg"
              required
            />
          </div>
          <div>
            <label
              htmlFor="emailAddress"
              className="block text-sm font-medium text-gray-700"
            >
              Email Address
            </label>
            <input
              type="email"
              id="emailAddress"
              name="emailAddress"
              value={formData.emailAddress}
              onChange={handleInputChange}
              className="flex w-full py-2 px-10 border outline-none my-1 pl-5 font-poppinsLight focus:border-gray-700 focus:ring-gray-700 rounded-lg"
              required
            />
          </div>
          <div>
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700"
            >
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              rows={4}
              className="flex w-full py-2 px-10 border outline-none my-1 pl-5 font-poppinsLight resize-none focus:border-gray-700 focus:ring-gray-700 rounded-lg"
              required
            ></textarea>
          </div>
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Your Number
            </label>
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              className="flex w-full py-2 px-10 border outline-none my-1 pl-5 font-poppinsLight focus:border-gray-700 focus:ring-gray-700 rounded-lg"
              required
            />
          </div>
          <div className="w-full flex justify-center">
            <button
              type="submit"
              className=" w-1/2  text-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-crunchies-red hover:bg-crunchies-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-crunchies-red"
            >
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}
