import { useCallback, useState } from 'react';

import { Library } from '@googlemaps/js-api-loader';
import LoadingIndicator from '../LoadingIndicator';
import { getUserLocation } from '@/utils/helpers';
import { useJsApiLoader } from '@react-google-maps/api';

export default function LocationInput() {
  const libs: Library[] = ['places'];

  // Initialize Google Maps
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY as string,
    libraries: libs,
  });

  const [input, setInput] = useState<HTMLInputElement | null>(null);

  const placeAutoCompleteRef = useCallback((node: HTMLInputElement) => {
    if (node !== null) {
      setInput(node);
    }
  }, []);

  getUserLocation(input);

  if (!isLoaded) {
    return <div className="flex justify-center items-center"><LoadingIndicator size="lg" /></div>;
  }

  return (
    <div className="grid gap-3">
      {/* Location preference */}
      <label htmlFor="userLocation" className="text-xs md:text-sm font-bold">
        Where are you ordering from?
      </label>

      <input
        type="text"
        name="userLocation"
        id="userLocation"
        placeholder="Type in your preferred delivery location, closest landmark, nearest road etc."
        ref={placeAutoCompleteRef}
        className="w-full p-4 border border-[#ccc] outline-none rounded-lg"
      />
    </div>
  );
}
