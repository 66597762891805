import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { HeaderNavMenu } from './NavMenu';

type ModalProps = {
  openModal: boolean;
  closeModal: () => void;
};

export default function NavMenuDialog({ openModal, closeModal }: ModalProps) {
  return (
    <Transition appear show={openModal} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={closeModal}>
        <div className="fixed inset-0">
          <div className="max-w-[1440px] mx-auto">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="bg-crunchies-white shadow-lg rounded-lg p-5 w-full max-w-lg ml-auto grid gap-5 h-full">
                <div className="flex justify-between items-center">
                  {/* Heading */}
                  <h3 className="grid gap-2">
                    <div className="text-crunchies-red text-5xl">
                      Hey Cruncher...
                    </div>
                    <div className="text-black">Let's explore!</div>
                  </h3>

                  {/* Close button */}
                  <button
                    className="border border-crunchies-red rounded-full"
                    onClick={closeModal}
                  >
                    <XMarkIcon className="w-6 text-crunchies-red" />
                  </button>
                </div>

                {/* Menu */}
                <HeaderNavMenu />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
