import React, { useState } from 'react';
import {
  BoltIcon,
  BuildingStorefrontIcon,
  HomeIcon,
  NewspaperIcon,
  SparklesIcon,
  UserCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { PulseLoader } from 'react-spinners';
import SupportModalForm from './Support';

export function HeaderNavMenu() {
  const [loading, setLoading] = useState<number | null>(null);
  const navItems = [
    {
      url: '/',
      icon: <HomeIcon className="w-6 lg:hidden" />,
      name: 'Home',
    },
    {
      url: '/menu',
      icon: <BuildingStorefrontIcon className="w-6 lg:hidden" />,
      name: 'Menu',
    },
    {
      url: '/offers',
      icon: <BoltIcon className="w-6 lg:hidden" />,
      name: 'Offers',
    },
    {
      url: '/fan-club',
      icon: <SparklesIcon className="w-6 lg:hidden" />,
      name: 'Fan Club',
    },
    {
      url: '/blog',
      icon: <NewspaperIcon className="w-6 lg:hidden" />,
      name: 'Blog',
    },
    {
      url: '/about-us',
      icon: <UserGroupIcon className="w-6 lg:hidden" />,
      name: 'About Us',
    },
    {
      url: '/account',
      icon: <UserCircleIcon className="w-6 lg:hidden" />,
      name: 'My Account',
    },
  ];

  return (
    <nav className="grid gap-5 py-5 grid-cols-2 lg:flex lg:items-center text-crunchies-red">
      {navItems.map((item, index) => (
        <a
          href={item.url}
          className="flex gap-2 justify-between items-center bg-crunchies-white shadow-lg p-3 rounded-lg"
          onClick={() => setLoading(index)}
          key={index}
        >
          <div className="flex gap-2 items-center">
            {item.icon}
            <div>{item.name}</div>
          </div>
          <PulseLoader size={5} loading={loading === index} color="#F61417" />
        </a>
      ))}
      <SupportModalForm />
    </nav>
  );
}
